.marker {
    /* background-image: url('mapbox-icon.png'); */
    /* background-color: rbga(255,255,255,0.5); */
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* border: 1px solid #eee; */
    cursor: pointer;
    display: flex;
    align-content: center;
    text-align: center;
    place-content: center;
    place-items: center;
    /* backdrop-filter: blur(4px); */
    font-size: 2rem;
}
    
.mapboxgl-popup-tip {
    padding: 0px;
}

.mapboxgl-popup {
    width: 66.666667%;
    max-width: 36rem;
}
    
.mapboxgl-popup-content {
    text-align: left;
    padding: 0px;
    background-color: transparent;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    border-bottom-color: hsl(var(--b1));
    }
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    border-top-color: hsl(var(--b1));
    }
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: hsl(var(--b1));
    }
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: hsl(var(--b1));
}